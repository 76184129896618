import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import HomeView from 'views/Home'

interface IndexPageProps extends PageProps {
  data: {
    allApiBanners: {
      nodes: []
    }
    allApiCategories: {
      nodes: []
    }
    allApiPosts?: {
      nodes: []
    }
    allApiUnits: {
      nodes: []
    }
  }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { allApiBanners, allApiCategories, allApiPosts, allApiUnits } = data

  return (
    <DefaultLayout>
      <Seo />
      <HomeView
        banners={allApiBanners.nodes}
        categories={allApiCategories.nodes}
        posts={allApiPosts?.nodes || []}
        units={allApiUnits.nodes}
      />
    </DefaultLayout>
  )
}

export const query = graphql`
  query HomeQuery {
    allApiBanners {
      nodes {
        id
        title
        body
        cta
        to
        showCaption
        showStores
        image
        image_square
      }
    }

    allApiCategories {
      nodes {
        id
        slug
        title
        body
        icon
        util
        image
      }
    }

    allApiPosts(limit: 3, sort: { order: DESC, fields: posted_at }) {
      nodes {
        id
        slug
        category {
          slug
        }
        title
        subtitle
        image
      }
    }

    allApiUnits(sort: { order: DESC, fields: id }) {
      nodes {
        id
        type
        schedule
        phone
        address
        neighborhood
        image
      }
    }
  }
`

export default IndexPage
