import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { CategoryProps } from 'components/app/Category'
import { UnitProps } from 'components/app/Unit'

import { Block, Carousel, Text, Title } from 'components/shared'
import { Ribbon } from 'components/helpers'
import {
  Post,
  Category,
  Hero,
  InstagramFeed,
  Social,
  Tip,
  Unit
} from 'components/app'
import { StaticImage } from 'gatsby-plugin-image'

interface ViewProps {
  banners?: []
  categories?: CategoryProps[]
  units?: UnitProps[]
  posts?: BlogPost[]
}

const HomeView: React.FC<ViewProps> = ({
  banners = [],
  categories = [],
  units = [],
  posts = []
}) => (
  <>
    <Block tag="section">
      <Hero slides={banners} />
    </Block>

    <Block height="size88" bgColor="white" position="relative" zIndex={2}>
      <Ribbon angle={0} />
    </Block>

    <Block
      tag="section"
      paddingTop="size48"
      paddingBottom={{ xxxs: 'size0', md: 'size96' }}
    >
      <Container>
        <Row>
          <Col xs={12} sm={4}>
            <Tip
              title="Fácil de pedir"
              body="Aplicativo fácil de usar, sem complicações, sem arrodeio!"
              icon="cellphone"
            />
          </Col>
          <Col xs={12} sm={4}>
            <Tip title="Entrega" body="Rápida e segura!" icon="truck" />
          </Col>
          <Col xs={12} sm={4}>
            <Tip
              title="Gostosa de lascar"
              body="Os melhores sabores de um jeito que você nunca provou!"
              icon="thumbs-up"
            />
          </Col>
        </Row>
      </Container>
    </Block>

    <Block tag="section" paddingTop="size48" paddingBottom="size88">
      <Container>
        <Row justify="space-between">
          <Col xs={12} md={5}>
            <Block
              tag="header"
              position="relative"
              marginBottom={{ xxxs: 'size24', md: 'size0' }}
            >
              <Block
                display={{ xxxs: 'none', md: 'block' }}
                position="absolute"
                zIndex={3}
                right="5%"
                top="-50%"
                width={280}
              >
                <StaticImage
                  src="../../images/menina-com-pizza.png"
                  alt="Menina comendo uma fatia de pizza"
                  layout="fullWidth"
                  placeholder="blurred"
                />
              </Block>
              <Block
                position="relative"
                zIndex={3}
                width="80%"
                paddingTop={{ xxxs: 'size0', md: 'size120' }}
              >
                <Title size="h3" lineHeight={1} maxWidth={250} uppercase>
                  Conheça nossas opções de lascas
                </Title>
                <Text fontSize={14} marginTop="size12">
                  Prove os nossos sabores exclusivos em cada categoria.
                </Text>
              </Block>
            </Block>
          </Col>
          <Col xs={12} md={7}>
            <Carousel
              swiper={{
                initialSlide: 0,
                observer: true,
                watchOverflow: true,
                simulateTouch: false,
                spaceBetween: 48,
                slidesPerView: 1,
                autoplay: {
                  delay: 5000
                },
                breakpoints: {
                  0: {
                    slidesPerView: 1
                  },
                  600: {
                    slidesPerView: 2
                  },
                  768: {
                    slidesPerView: 3
                  },
                  991: {
                    slidesPerView: 1
                  }
                }
              }}
            >
              {categories.map((category) => (
                <Category key={category.id} {...category} />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Block>

    <Block height="size110" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="light" angle={0.5} />
      <Ribbon angle={-3} top={12} delay="-10s" duration="300s" />
    </Block>

    {posts.length > 0 && (
      <Block tag="section" paddingTop="size48" paddingBottom="size88">
        <Container>
          <Block
            tag="header"
            display="flex"
            alignItems="center"
            marginBottom="size48"
          >
            <Title
              size="h3"
              icon={{
                name: 'news',
                color: 'brown800',
                width: 'size20',
                height: 'size20',
                marginLeft: 'size48',
                marginRight: 'size8'
              }}
              appearance="section"
              marginRight="size12"
              uppercase
            >
              Novidades
            </Title>
            <Text fontSize={14}>Confira as novidades das Lasca de Pizza</Text>
          </Block>
          <Block tag="article">
            <Carousel
              swiper={{
                initialSlide: 0,
                observer: true,
                watchOverflow: true,
                simulateTouch: false,
                autoplay: {
                  delay: 4000
                },
                spaceBetween: 32,
                slidesPerView: 3,
                breakpoints: {
                  0: {
                    spaceBetween: 0,
                    slidesPerView: 1
                  },
                  768: {
                    spaceBetween: 32,
                    slidesPerView: 3
                  }
                }
              }}
            >
              {posts.map((post) => (
                <Post
                  key={post.id}
                  category={post.category.slug}
                  slug={post.slug}
                  title={post.title}
                  subtitle={post.subtitle}
                  image={post.image}
                />
              ))}
            </Carousel>
          </Block>
        </Container>
      </Block>
    )}

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="primary" angle={0.4} delay="-12s" />
    </Block>

    <Block
      tag="section"
      paddingTop="size64"
      paddingBottom={{ xxxs: 'size0', sm: 'size48' }}
    >
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Text fontSize={14} uppercase>
            Unidades
          </Text>
          <Title size="h3" uppercase>
            Faça-nos uma visita
          </Title>
        </Block>
        <Block tag="article">
          <Carousel
            swiper={{
              initialSlide: 0,
              observer: true,
              watchOverflow: true,
              simulateTouch: false,
              loop: true,
              autoplay: {
                delay: 3000
              },
              spaceBetween: 32,
              slidesPerView: 2,
              breakpoints: {
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1
                },
                962: {
                  spaceBetween: 32,
                  slidesPerView: 2
                }
              }
            }}
          >
            {units.map((unit) => (
              <Unit key={unit.id} {...unit} />
            ))}
          </Carousel>
        </Block>
      </Container>
    </Block>

    <Block tag="section" paddingTop="size48" overflow="hidden">
      <Container>
        <Block
          tag="header"
          display="flex"
          alignItems="center"
          paddingBottom={{
            xxxs: 'size48',
            sm: 'size64',
            lg: 'size96'
          }}
          marginBottom="size48"
        >
          <Title
            size="h3"
            icon={{
              name: 'like',
              color: 'brown800',
              width: 'size20',
              height: 'size20',
              marginLeft: 'size48',
              marginRight: 'size8'
            }}
            lineHeight={1}
            appearance="section"
            marginRight="size12"
            uppercase
          >
            Siga nossas redes
          </Title>
          <Social />
        </Block>
      </Container>
      <Block tag="article" bgColor="yellow400" transform="translateY(25%);">
        <Container>
          <Block transform="translateY(-50%);">
            <InstagramFeed />
          </Block>
        </Container>
      </Block>
    </Block>
  </>
)

export default HomeView
